



























import api from "@/api";
import { ContractType, SalaryPerNationalityCollectionModel } from "@/api/generated";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";

@Component
export default class SalaryPerJobRoleChart extends Vue {
  @Prop({default: false}) isCompaniesTable!: boolean;

  private years: Array<number> = [];
  private selectedDate = "";
  private data: SalaryPerNationalityCollectionModel = {
    categories: [],
    data: [],
    salaryPerNationalityModels: []
  };
  private selectedYear = new Date().getFullYear();

  private barChartSeries: any = {};
  private barChartOptions: any = {};

  @Watch("selectedYear", { immediate: true })
  private async onSelectedDateChange() {
    if (!this.selectedYear) {
      this.barChartSeries = [
        {
          name: "Salary GBP",
          data: []
        }
      ];
      return;
    }

    const response = await api.CrewMemberVesselEnquiryService.apiCrewmembervesselenquirySalaryNationalityGet(
      this.selectedYear,
      this.isCompaniesTable ? ContractType.NUMBER_1 : ContractType.NUMBER_0

    );

    this.data = response.data;

    this.barChartSeries = [
      {
        name: "Salary GBP",
        data: this.data.data
      }
    ];
    this.barChartOptions = {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: this.data.categories
      },
      yaxis: {
        title: {
          text: "£ GBP"
        },
        labels: {
          formatter: function(val: number) {
            return "£ " + val.toFixed(2);
          }
        }
      },
      fill: {
        opacity: 1,
        colors: [this.$vuetify.theme.themes.light.primary]
      },
      tooltip: {
        y: {
          formatter: function(val: number) {
            return "£ " + val.toFixed(2);
          }
        }
      }
    };
  }

  onChangeYear(value: number) {
    if (this.selectedYear + value < 0) {
      return;
    }

    this.selectedYear += value;
  }

  get dateDisplay() {
    return `Data for ${this.selectedYear}`;
  }
}
